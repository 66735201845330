import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Paper = makeShortcode("Paper");
const TextCustom = makeShortcode("TextCustom");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "its-like-talking-to-a-brick-wall"
    }}>{`It's like talking to a brick wall!`}</h1>
    <p>{`If your teenager doesn't respond to your attempt to communicate, try stepping back and looking at how you communicate.`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom paragraph customColor="green" variant="h3" align="center" mdxType="TextCustom">
        Do
      </TextCustom>
      <p>
        <b>Respect</b> your teenager's opinion, even if you disagree.
        <br />
        <i>“I haven't thought of it that way.”</i>
      </p>
      <p>
        <b>Listen</b> - teenagers often talk more when parents talk less.
      </p>
      <p>
        <b>Tune in to your teenager</b>. They might be communicating with you in their own way. A slamming door could be
        your teenager's way of telling you they've had a rough day at school.
      </p>
      <p>
        <b>Consider your reactions</b>. You may find your teenager's interests strange or dull and show your dislike on
        your face. Instead, <b>stay interested and engaged</b>.
      </p>
    </Paper>
  </Grid>
  <Grid xs={12} sm={6} item mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom paragraph customColor="maroon" variant="h3" align="center" mdxType="TextCustom">
        Don't
      </TextCustom>
      <p>
        <b>Make critical comments</b>. Rather than blame your teenager for not communicating with you, express your
        desire to connect with them. Instead of <i>"You never talk to me anymore!"</i>, try{' '}
        <i>"I'd love to spend some time with you. What would you like to do together?"</i>
      </p>
      <p>
        <b>Nag at your teenager</b>. If the only thing your teenager hears from you is what they should be doing, they
        are less likely to talk to you.
      </p>
      <p>
        <b>Try to fix everything</b>. Sometimes teenagers don't want advice, they just want to be listened to. Let your
        teenager share their world without swooping in to save them.
      </p>
    </Paper>
  </Grid>
    </Grid>
    <TextCustom paragraph customColor="blue" mdxType="TextCustom">
  <i>
    What's the best way your Mum or Dad could talk to you? What would you like from them when you are talking together?
  </i>{' '}
  Hear what Nicky, 13 years old, says…
    </TextCustom>
    <BasicAudio src="/audios/module_1_audio_clip.mp3" mdxType="BasicAudio" />
    <ButtonDialog linkText="Read Transcript" title={<i>
      What's the best way your Mum or Dad could talk to you? What would you like from them when you are talking
      together?
    </i>} mdxType="ButtonDialog">
  <p>Nicky, 13, says…</p>
  <p>
    I would like my parents NOT to interrupt me when we're talking together. Especially, don't lecture me and say “when
    I was young...”.
  </p>
  <p>
    I really don't want their ‘pearls of wisdom' jammed down my throat when I'm just trying to talk about my day. My
    parents don't always have to give their opinion.
  </p>
  <p>
    They could just sit with their thoughts. I really hate it when they tell me what I should've done. It's not like
    they've got it 100% right <i>all</i> the time.
  </p>
  <p>
    When my parents talk to me, they yell, but when I yell back they get angry at ME! But they're the ones yelling in
    the first place! And I'm only yelling to get my side of the story in, but I end up getting in more trouble.
  </p>
  <p>
    I'd like my parents to show a little patience, and interest, but not too much interest. What I mean is, if the test
    results are bad, don't ask <i>why</i> I didn't do well and <i>don't</i> compare me with my brother.
  </p>
  <p>
    My mum keeps saying how good my brother is when he's not. Actually, don't ask about tests, if the results are good{' '}
    <i>I'll tell you</i>. I'd like my parents to listen and to not ask too many questions - just let the conversation
    happen.
  </p>
    </ButtonDialog>
    <SingleImage smallGridSize={12} gridSize={12} src="/images/m1/Brick_wall.svg" alt="connect hero image" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      